@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom css */

.btn-indigo {
	@apply py-2 px-4 bg-indigo-500 text-white font-semibold rounded-lg shadow-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-400 focus:ring-opacity-75;
}

.p {
	@apply py-4
}

.form-select {
	padding: 0;
}

.mapboxgl-popup-content {
	@apply bg-gray-900 !important;
}

.overlay-topleft {
	top: 0;
	left: 0;
}

.overlay-topright {
	top: 0;
	right: 0;
}

.overlay-bottomleft {
	bottom: 0;
	left: 0;
}

.overlay-bottomright {
	bottom: 0;
	right: 0;
	padding: 10px;
}

.map-overlay {
	position: absolute;
	padding: 10px;
	z-index: 1;
}